body {
  background-color: #fdfdfd;
  letter-spacing: 0.55px;
}

small {
  font-size: 0.6em;
}

code {
  background-color: lightgray;
  padding: 2px 4px;
  border-radius: 3px;
}

h2 {
  font-size: 0.95em;
}

h3 {
  font-size: 0.85em;
}

ul {
  padding-inline-start: 24px;
  @media all and (min-width: 768px) {
    padding-inline-start: 40px;
  }
}

.App {
  padding: 16px 16px;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  max-width: 880px;
}

.typed-header {
  display: inline;
  font-size: 16px;
}

@media screen and (min-width: 480px) {
  .typed-header {
    font-size: 32px;
  }

  .App {
    padding: 20px 30px;
    font-size: 1.5em;
  }
}