body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cv-text {
  font-size: 0.75em; }

.cv-section {
  margin-bottom: 24px; }
  @media all and (min-width: 768px) {
    .cv-section {
      margin-bottom: 40px; } }

.cv-subheader {
  margin: 0;
  opacity: 0.5; }

body {
  background-color: #fdfdfd;
  letter-spacing: 0.55px; }

small {
  font-size: 0.6em; }

code {
  background-color: lightgray;
  padding: 2px 4px;
  border-radius: 3px; }

h2 {
  font-size: 0.95em; }

h3 {
  font-size: 0.85em; }

ul {
  -webkit-padding-start: 24px;
          padding-inline-start: 24px; }
  @media all and (min-width: 768px) {
    ul {
      -webkit-padding-start: 40px;
              padding-inline-start: 40px; } }

.App {
  padding: 16px 16px;
  font-family: 'Work Sans', sans-serif;
  font-size: 14px;
  max-width: 880px; }

.typed-header {
  display: inline;
  font-size: 16px; }

@media screen and (min-width: 480px) {
  .typed-header {
    font-size: 32px; }
  .App {
    padding: 20px 30px;
    font-size: 1.5em; } }

